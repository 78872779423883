import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import iCalendarPlugin from '@fullcalendar/icalendar'

const Calendar = () => {
    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, iCalendarPlugin]}
            initialView="timeGridWeek"
            weekends={true}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            events={{
                url: '/calendar.ics',
                format: 'ics'
            }}
        />
    )
}

export default Calendar