import React, { useState, useEffect, KeyboardEvent } from 'react';

import '../styles/HyperbolicGreen.css';

const HyperbolicGreen = () => {
    const [boxColor, setBoxColor] = useState('#ff00ff');

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === ' ') {
      setBoxColor((prevColor) => (prevColor === '#ff00ff' ? '#00ff00' : '#ff00ff'));
    }
  };

    return (
        <div className="fullscreen-box" style={{ backgroundColor: boxColor }}>
            {<><p>Focus on the X for 30s and then press [Space]<br/>
            <h1>X</h1></p>
            </>}
        </div>
    );
};

export default HyperbolicGreen;